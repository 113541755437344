import React from "react"
import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import Seo from "../components/seo"
import Constants from "../config/constants"
import contact from "../images/contact.svg"

const styles = makeStyles(theme => {
  return {
    contactImage: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%"
    }
  }
})

const ContactContainer = () => {
  const classes = styles()
  return (
    <React.Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3} justifyContent="center"
              alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" component='p'
                        className={classes.lead}>
              You can contact us using this form or call via{" "}
              <Link href={`tel:${Constants.siteMetadata.phone}`}
                    color="secondary">
                {Constants.siteMetadata.phone}
              </Link>
              &nbsp;or email at{" "}
              <Link href={`mailto:${Constants.siteMetadata.email}`}
                    color="secondary">
                {Constants.siteMetadata.email}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <form name="Contact Form" method="POST" action="/thank-you"
                  data-netlify="true" netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="Contact Form"/>
              <input type="hidden" name="bot-field"/>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth
                             type="text"
                             name="name" label="Name"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth
                             type="email"
                             name="email" label="Email"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth type="tel"
                             name="phone number"
                             label="Phone Number"/>
                </Grid>
                <Grid item xs={12}>
                  <TextField variant="outlined" required fullWidth multiline
                             name="message" id='message'
                             label="Message"
                             minRows="5"/>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" fullWidth color="primary"
                          type="submit" size="large">Send</Button>
                </Grid>
              </Grid>
            </form>

          </Grid>
          <Grid item xs={0} md={1}>
          </Grid>
          <Grid item xs={12} md={5}>
            <img src={contact} className={classes.contactImage}
                 alt={`Contact`}/>
          </Grid>
        </Grid>

      </Container>
    </React.Fragment>
  )
}

export default function Contact() {

  return (
    <Layout header="Contact">
      <Seo noIndex/>
      <ContactContainer/>
    </Layout>
  )
}
