import React from "react"
import { Helmet } from "react-helmet"
import Constants from "../config/constants"

const Seo = ({ title, description, image, article, noIndex = false }) => {
  const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : ""

  const {
    defaultTitle,
    defaultDescription,
    titleTemplate,
    siteUrl,
    defaultImage,
    twitterUsername
  } = Constants.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`
  }

  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta name="description" content={seo.description}/>
      <meta name="image" content={seo.image}/>

      {seo.url && <meta property="og:url" content={seo.url}/>}

      {(article ? true : null) &&
      <meta property="og:type" content="article"/>}

      {seo.title && <meta property="og:title" content={seo.title}/>}

      {seo.description && (
        <meta property="og:description" content={seo.description}/>
      )}

      {seo.image && <meta property="og:image" content={seo.image}/>}

      <meta name="twitter:card" content="summary_large_image"/>

      {twitterUsername && (
        <meta name="twitter:creator" content={twitterUsername}/>
      )}

      {seo.title && <meta name="twitter:title" content={seo.title}/>}

      {seo.description && (
        <meta name="twitter:description" content={seo.description}/>
      )}

      {seo.image && <meta name="twitter:image" content={seo.image}/>}

      {noIndex && <meta name="robots" content="noindex"/>}
    </Helmet>
  )
}

export default Seo